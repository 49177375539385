import { graphql, useStaticQuery } from "gatsby";
import { GetStartedTransitionalCTAQuery } from "./query.generated";

export type CMSTransitionalCTAData =
  GetStartedTransitionalCTAQuery["cms"]["site"];

export function useStaticTransitionalCTAQuery(): CMSTransitionalCTAData {
  const data = useStaticQuery<GetStartedTransitionalCTAQuery>(
    graphql`
      query GetStartedTransitionalCTA {
        cms {
          site(hostname: "www.italymondo.com") {
            contactPage: pages(
              contentType: "italymondo_contact.IMContactPage"
            ) {
              ... on CMS_IMContactPage {
                transitionalCta {
                  action {
                    ... on CMS_CollectEmailBlock {
                      __typename
                      collectEmail
                    }
                    ... on CMS_LinkPageBlock {
                      __typename
                      page {
                        url
                      }
                    }
                    ... on CMS_ShowPopupBlock {
                      __typename
                      description
                      title
                    }
                  }
                  buttonText
                  description
                  shadowImage {
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
