import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled("div")`
  ${({ theme }): SerializedStyles => css`
    background: linear-gradient(
        to top,
        ${theme.palette.site.shadowHeader.dark} 60%,
        transparent 40%
      )
      no-repeat;
  `}
`;
