import React from "react";
import {
  bookACallIFrame,
  TransitionalCTA as TransitionalCTAComponent,
} from "@italymondo/core-ui";
import { useStaticTransitionalCTAQuery } from "./query";
import { Container } from "./styles";

export function TransitionalCTA(): React.ReactElement | null {
  const data = useStaticTransitionalCTAQuery();

  if (!data.contactPage[0].transitionalCta) {
    return null;
  }

  return (
    <Container>
      <TransitionalCTAComponent
        action={data?.contactPage[0].transitionalCta.action[0]}
        buttonText={data?.contactPage[0].transitionalCta.buttonText}
        description={data?.contactPage[0].transitionalCta?.description || ""}
        popupContent={bookACallIFrame}
        shadowImage={data?.contactPage[0].transitionalCta?.shadowImage?.url}
        title={data?.contactPage[0].transitionalCta?.title || ""}
      />
    </Container>
  );
}
