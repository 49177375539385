import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Container } from "../Container";

export const StyledContainer = styled(Container)`
  ${({ theme }): SerializedStyles => css`
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(8)};
    ${theme.breakpoints.up("md")} {
      padding-top: ${theme.spacing(12)};
      padding-bottom: ${theme.spacing(12)};
    }
    ${theme.breakpoints.up("xl")} {
      padding-top: ${theme.spacing(14)};
      padding-bottom: ${theme.spacing(14)};
    }
  `};
`;
