import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { useStaticContactFormQuery } from "./query";
import { StyledContactFormComponent } from "./styles";

export function ContactForm(): React.ReactElement | null {
  const data = useStaticContactFormQuery();

  if (!data) {
    return null;
  }

  return (
    <StyledContactFormComponent
      darkMode
      confirmationPageUrl={
        getPathname(data.site?.contactPage[0]?.heroConfirmationPage?.url) || "/"
      }
      description={data.site?.contactPage[0]?.heroDescription || ""}
      lazyLoad={false}
      title={data.site?.contactPage[0]?.heroHeadline || ""}
    />
  );
}
