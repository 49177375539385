import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const OfficesContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(2)};
  `}
`;

export const OfficeCard = styled("div")`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(3)};
    box-shadow: ${theme.shadows[2]};
    border-radius: ${theme.shape.siteBorderRadius.lg};
    background-color: ${theme.palette.background.paper};
    ${theme.cssMixins.rowVCentered};
    justify-content: space-between;
    > img {
      width: 40%;
      margin-left: ${theme.spacing(3)};
    }
    height: 150px;
    ${theme.breakpoints.up("sm")} {
      height: 225px;
      > img {
        width: 50%;
      }
    }
    ${theme.breakpoints.up("md")} {
      padding: ${theme.spacing(6)};
    }
    ${theme.breakpoints.up("lg")} {
      height: 340px;
    }
  `};
`;

export const CountryMap = styled("img")`
  height: 100%;
  width: 100%;
`;
