import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { ContactForm } from "@italymondo/core-ui";

export const StyledContactFormComponent = styled(ContactForm)`
  ${({ theme }): SerializedStyles => css`
    padding-top: ${theme.cssMixins.appHeaderHeightMobile};
    ${theme.breakpoints.up("sm")} {
      padding-top: ${theme.cssMixins.appHeaderHeightDesktop};
    }
  `}
`;
