import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import {
  ContactForm,
  OurOffices,
  OurProcess,
  TestimonialWall,
  TransitionalCTA,
} from "../../sections/GetStarted";
import { GetStartedQuery } from "./index.generated";

type PpGetStartedPage = {
  data: GetStartedQuery;
  location?: {
    pathname?: string;
  };
};

export default function GetStartedPage({
  data: {
    cms: { site },
  },
  location,
}: PpGetStartedPage): React.ReactElement {
  const alertBarData = site.contactPage[0].alertBar?.message
    ? {
        link: {
          text: site.contactPage[0].alertBar?.anchorText || "",
          url: getPathname(site.contactPage[0].alertBar?.page.url) || "",
        },
        text: site.contactPage[0].alertBar?.message || "",
      }
    : null;

  return (
    <Layout
      alertBar={alertBarData}
      showProgressBar={site.contactPage[0].progressBar}
    >
      <SEO
        description={site.contactPage[0]?.listingSummary || ""}
        image={site.contactPage[0]?.listingImage?.url}
        location={location}
        title={site.contactPage[0]?.listingTitle || ""}
      />
      <ContactForm />
      <OurOffices />
      <OurProcess />
      {/* <TestimonialWall /> */}
      <TransitionalCTA />
    </Layout>
  );
}

export const query = graphql`
  query GetStarted {
    cms {
      site(hostname: "www.italymondo.com") {
        contactPage: pages(contentType: "italymondo_contact.IMContactPage") {
          ... on CMS_IMContactPage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
