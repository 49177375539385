import { graphql, useStaticQuery } from "gatsby";
import { GetStartedOurOfficesQuery } from "./query.generated";

export type CMSOurOfficesData = GetStartedOurOfficesQuery["cms"]["site"];

export function useStaticOurOfficesQuery(): CMSOurOfficesData {
  const data = useStaticQuery<GetStartedOurOfficesQuery>(
    graphql`
      query GetStartedOurOffices {
        cms {
          site(hostname: "www.italymondo.com") {
            contactPage: pages(
              contentType: "italymondo_contact.IMContactPage"
            ) {
              ... on CMS_IMContactPage {
                officesDescription
                officesSectionName
                officesTitle
              }
            }
            companydetail {
              office1Country
              office1CountryMap {
                url
              }
              office1FullAddress
              office2Country
              office2CountryMap {
                url
              }
              office2FullAddress
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
