import { graphql, useStaticQuery } from "gatsby";
import { GetStartedContactFormQuery } from "./query.generated";

export type CMSContactFormData = GetStartedContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<GetStartedContactFormQuery>(
    graphql`
      query GetStartedContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            contactPage: pages(
              contentType: "italymondo_contact.IMContactPage"
            ) {
              ... on CMS_IMContactPage {
                heroConfirmationPage {
                  url
                }
                heroDescription
                heroHeadline
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
