/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef } from "react";
import {
  RichText,
  SectionContainer,
  SectionHeading,
  useHasBeenAlmostVisible,
  useResponsive,
} from "@italymondo/core-ui";
import { Grid, Stack, Typography } from "@mui/material";
import { useStaticOurOfficesQuery } from "./query";
import { CountryMap, OfficeCard, OfficesContainer } from "./styles";

export function OurOffices(): React.ReactElement | null {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(
    elementRef,
    "0px 0px 200px 0px"
  );

  const data = useStaticOurOfficesQuery();
  const r = useResponsive();

  if (!data) {
    return null;
  }

  return (
    <SectionContainer>
      <Stack ref={elementRef} alignItems="center">
        <SectionHeading
          description={data?.contactPage[0]?.officesDescription}
          sectionName={data.contactPage[0].officesSectionName || ""}
          textAlign="center"
          title={data?.contactPage[0]?.officesTitle}
        />
        <OfficesContainer
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={4}
        >
          <Grid item sm={6} xs={12}>
            <OfficeCard>
              <div>
                <Typography gutterBottom color="primary" variant="h3">
                  {data?.companydetail?.office1Country}
                </Typography>
                <RichText
                  color="primary"
                  text={data?.companydetail?.office1FullAddress}
                  variant={r({ lg: "body1", xs: "body2" })}
                />
              </div>
              {shouldRenderContent ? (
                <CountryMap src={data?.companydetail?.office1CountryMap?.url} />
              ) : null}
            </OfficeCard>
          </Grid>
          <Grid item sm={6} xs={12}>
            <OfficeCard>
              <div>
                <Typography gutterBottom color="primary" variant="h3">
                  {data?.companydetail?.office2Country}
                </Typography>
                <RichText
                  color="primary"
                  text={data?.companydetail?.office2FullAddress}
                  variant={r({ lg: "body1", xs: "body2" })}
                />
              </div>
              {shouldRenderContent ? (
                <CountryMap src={data?.companydetail?.office2CountryMap?.url} />
              ) : null}
            </OfficeCard>
          </Grid>
        </OfficesContainer>
      </Stack>
    </SectionContainer>
  );
}
